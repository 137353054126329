import * as React from "react"
import  EventCard from "../components/EventCard";
import GalleryCard from '../components/GalleryCard';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Box from "@material-ui/core/Box"
import { StaticImage } from "gatsby-plugin-image"
import Backdrop from '@material-ui/core/Backdrop';
import Carousel from '../components/Carousel'
import { GatsbyImage } from "gatsby-plugin-image";
import { formatEventHours } from '../components/EventCard';
import { generateEventPageUrl } from '../utils';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Modal from '@material-ui/core/Modal';
import { navigate, Link } from "gatsby"

//TODO move to utils
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const GalleryPage = (props) => {

  const data = props.data;
  let maxCols = 3;
  let totalCol = maxCols;
  
  const getColSize = function(index, arr){

    let availableRowSpace = maxCols - totalCol%maxCols;
    // if (availableRowSpace == arr[index-1]) in the future avoid triple results of same space 
    const newCol = getRandomInt(1, availableRowSpace);
    totalCol+= newCol;
    return newCol;

  }

  const layoutSequence = [
    1, 2, 2, 1, 1, 1, 1, 2, 1
  ]
  const [selectedGalleryItem, setSelectedGalleryItem] = React.useState(props.pageContext.galleryItemData ? props.pageContext.galleryItemData.node : null);


  return (<Layout>
    <SEO title={selectedGalleryItem ?  selectedGalleryItem.date : 'Gallery'} 
          image={selectedGalleryItem ? selectedGalleryItem.childImageSharp.resize : null}
          description={selectedGalleryItem ? selectedGalleryItem.details : 'Photos from our various clinics and tournaments'}
          />
        {/* <Typography variant="h3" style={{color: '#243E83', alignSelf: 'center'}}>May 2021</Typography>
        <Typography variant="h4" style={{alignSelf: 'center'}}><i>All Skills Clinics</i></Typography> */}
        <GridList cellHeight={400} cols={3} style={{backgroundColor: '#F78D1F'}}>
            {data.gallery.edges.map((edge, index, arr) => {
            return( <GridListTile cols={index == 0 ? maxCols : layoutSequence[(index+1)%layoutSequence.length]} shareUrl={props.location.origin+'/'+edge.node.relativePath}>
                <Link to={`/gallery/${edge.node.name}`}>
                    <GalleryCard 
                    image={edge.node.childImageSharp.gatsbyImageData} 
                    shareUrl={props.location.origin+'/'+edge.node.relativePath}
                    id={`photo-${edge.node.name}`}
                    expanded={false}
                    variant="popover"
                    styleOverride={{width: '100%', height: '100%'}}
                    />
                </Link>
                </GridListTile>)
            })}
        </GridList>
        {selectedGalleryItem && <Modal open={selectedGalleryItem!=null} onClose={() => { navigate(`/gallery/#photo-${selectedGalleryItem.name}`);}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <GalleryCard 
            image={selectedGalleryItem.childImageSharp.gatsbyImageData} 
            shareUrl={props.location.origin+'/gallery/'+selectedGalleryItem.name}
            expanded={true}
            details={selectedGalleryItem.details}
            date={selectedGalleryItem.date}
            />
        </Modal>}
  </Layout>)
}

export default GalleryPage

export const query = graphql`
  query {
    gallery: allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
        edges {
          node {
            childImageSharp {
              id
              gatsbyImageData(placeholder: BLURRED)
            }
            relativePath
            name
          }
        }
      }
  }
`
